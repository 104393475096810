/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable guard-for-in */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import Lightbox from 'react-awesome-lightbox'

import { normalizeValues } from 'helpers'
import { MODAL_STYLE } from 'core/theme'
import 'core/theme/imageZoom.css'
import { ReactComponent as CloseModalSVG } from 'assets/close-modal.svg'
import { useDispatch, useSelector } from 'react-redux'
import { addUndoAction, removeUndoIdAction } from 'features/undoAction/undoActionSlice'
import { useGetLoanBankCardsData, useGetPhotoData } from 'features/loan/loanSelectors'
import { fetchBankCardData, fetchDeleteImageById } from 'features/loan/extra'
import { GlobalState } from 'index'

import { Button } from 'components'

import {
  StyledImageInfoBlock,
  StyledModalContent,
  StyledZoomBlock,
  StyledImageData,
  StyleCloseModal,
  StyledButtonBlockModal,
  StyledImageDeleteBlock,
} from './Modal.styles'

import { PassportData } from './components/PassportData'
import { RegistrationData } from './components/RegistrationData'
import { BankCardData } from './components/BankCardData'

interface IImageProps {
  created_at: string
  photo_url: string
  degree: number
  type: string
}

interface IModalContent {
  isOpen: boolean
  onRequestClose: () => void
  imageContent: any
  onClose: () => void
  claimsResolve?: boolean
  type: string
  removable?: boolean
  userId?: string
  loanStatus?: string
  isEditable?: boolean
}

const Image = (props: IImageProps): JSX.Element => {
  const imagesDataContracts = useSelector((store: GlobalState) => store.contracts.imageData)
  const imagesDataAgentLoans = useSelector((store: GlobalState) => store.agentLoans.imageData)
  const imagesDataUnderwriter = useGetPhotoData()
  const { created_at: date, photo_url: url, degree, type } = props

  let allData = []
  let currentIndex = 0
  const imagesData = {
    contracts: imagesDataContracts,
    agentsScans: imagesDataContracts,
    agentPhoto: imagesDataUnderwriter,
    registration: imagesDataUnderwriter,
    bankCard: imagesDataUnderwriter,
    passport: imagesDataUnderwriter,
    agentLoans: imagesDataAgentLoans,
  }

  for (const key in imagesData[type]) {
    imagesData[type][key].forEach((el) => {
      allData = [
        ...allData,
        { url: el.photo_url, title: normalizeValues(el.created_at, 'full_date') },
      ]
    })
  }

  allData.forEach((el, i) => {
    if (el.url === url) currentIndex = i
  })

  const images = allData

  return (
    <StyledImageData>
      <StyledZoomBlock rotateDegree={degree}>
        <Lightbox images={images} zoomStep={0.5} startIndex={currentIndex} />
      </StyledZoomBlock>
    </StyledImageData>
  )
}

export const ModalContent: React.FC<IModalContent> = ({
  isOpen,
  onRequestClose,
  imageContent,
  onClose,
  type,
  userId,
  removable = true,
  claimsResolve = true,
  isEditable,
}): JSX.Element => {
  const dispatch = useDispatch()

  const [isOpenItem, setIsOpenItem] = useState({ id: type })
  const [degree, setAngle] = useState<number>(0)

  const bankCardData = useGetLoanBankCardsData()
  const {
    passportData: passport,
    registrationData: registration,
    in_passport: inPassport,
  } = useSelector((store: GlobalState) => store.singleLoan)

  const showSidebarContent = ['registration', 'bankCard', 'passport', 'agentLoans']
  useEffect(() => {
    if (isOpen && userId) dispatch(fetchBankCardData({ id: userId }))
  }, [isOpen, userId])
  const handleOpen =
    ({ id }) =>
    (): void =>
      setIsOpenItem({
        id,
      })

  const removeImage = (e, id) => {
    e.stopPropagation()
    onRequestCloseHandler()
    dispatch(
      addUndoAction({
        title: 'Отменить удаление',
        action: () =>
          dispatch(
            fetchDeleteImageById({
              image_id: id,
              user_id: userId,
              onError: () => {
                dispatch(removeUndoIdAction(id))
              },
            })
          ),
        cancelAction: () => dispatch(removeUndoIdAction(id)),
        id,
      })
    )
  }

  const onRequestCloseHandler = () => {
    onRequestClose()
  }

  const propsData = {
    handleOpen,
    isOpenItem,
    userId,
    isEditable,
    claimsResolve,
    inPassport,
  }

  return (
    <Modal
      isOpen={isOpen}
      style={MODAL_STYLE}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onRequestCloseHandler}
      contentLabel='onRequestClose Example'
    >
      <StyledModalContent>
        <StyleCloseModal onClick={onClose}>
          <CloseModalSVG />
        </StyleCloseModal>
        {showSidebarContent.includes(isOpenItem.id) ? (
          <StyledImageInfoBlock>
            <>
              <PassportData {...propsData} passport={passport} />
              <RegistrationData {...propsData} registration={registration} />
              <BankCardData {...propsData} bankCardData={bankCardData} />
            </>

            <StyledButtonBlockModal>
              {claimsResolve && removable && (
                <Button onClick={(e) => removeImage(e, imageContent.image_id)} type='bigDanger'>
                  Удалить фото
                </Button>
              )}
            </StyledButtonBlockModal>
          </StyledImageInfoBlock>
        ) : (
          <>
            {claimsResolve && removable && (
              <StyledImageDeleteBlock>
                <Button onClick={(e) => removeImage(e, imageContent.image_id)} type='bigDanger'>
                  Удалить фото
                </Button>
              </StyledImageDeleteBlock>
            )}
          </>
        )}
        <Image {...imageContent} fullSize degree={degree} setAngle={setAngle} type={type} />
      </StyledModalContent>
    </Modal>
  )
}
