/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { ITableProps, ITableStatus } from './types'
import InfiniteScroll from 'react-infinite-scroll-component'
import StickyBox from 'react-sticky-box'
import { MouseEvent, useCallback, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'

import { addToast } from 'features/toast/toastSlice'
import { useGetAgentUserNamesWithIds } from 'features/dict/dictSelectors'
import { changeAgent } from 'features/agentLoans/extra'
import { fetchContracts } from 'features/contracts/extra'
import { copyToClipboard, formatDate, getAdminFio, normalizeValues } from 'helpers'
import { ToastTypes } from 'lib/toast/Toast'
import { GlobalState } from 'index'
import { AllView } from './MobileView'
import { Button, Loader, ModalConfirm, ScrollToTop, Select, TableHead } from 'components'
import { ContractType, IContractListingItem } from 'features/contracts/types'

import { BODY_STRUCTURE, COLUMN_TEMPLATE, HEAD_STRUCTURE } from './tableStructure'

import {
  StyledMessagesWrapper,
  StyledRowWrapper,
  StyledStatus,
  StyledTitle,
  StyledValue,
  StyledValueCount,
  StyledCopyLink,
  StyledCreateDate,
  StyledInfo,
} from './Table.styles'

import {
  StaledContainer,
  StyledEmptyLabel,
  StyledInfiniteLoader,
  StyledTableWrapper,
} from '../ContractPage.styles'
import { StyledSelectAgent } from 'pages/AgentList/AgentPage.styles'

import { HiOutlineClipboardCopy } from 'react-icons/hi'
import { ReactComponent as MessagesSVG } from 'assets/messages.svg'
import { ReactComponent as CopySuccessSVG } from 'assets/copy_link_success.svg'

interface IShowModal {
  title: string
  description: string
  show: boolean
}

const initialModalState = { title: '', description: '', action: null, show: false }

export const Table = (props: any): JSX.Element => {
  const { type, fetchMoreData, checkHasMore, contracts } = props

  const { data: filters } = useSelector((store: GlobalState) => store.contractFilters)
  const dictData = useSelector((state: GlobalState) => state.dict)

  const agentUserNamesWithIds = useGetAgentUserNamesWithIds() || []

  const [showModal, setShowModal] = useState<IShowModal>(initialModalState)
  const [customerPhone, setCustomerPhone] = useState<string>('')
  const [assignAgent, setAssignAgent] = useState<string>('')

  const history = useHistory()
  const { path } = useRouteMatch()

  const AgentUsernamesList = agentUserNamesWithIds.map((value) => ({
    label: getAdminFio(value.username, dictData),
    value: value.username,
  }))

  const handleOpenContract = (e, id: string) => {
    e.preventDefault()
    history.push(`${path}/${id}`)
  }

  const handleOpenComments = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
  }

  const handleCopyLink = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
  }

  const dataList = Object.values(contracts[type]?.data || {})

  const mobileItems = useCallback(
    (contract) => ({
      [ContractType.allContracts]: <AllView contract={contract} />,
      [ContractType.active]: <AllView contract={contract} />,
      [ContractType.overdue]: <AllView contract={contract} />,
      [ContractType.repayment_date]: <AllView contract={contract} />,
    }),
    [dataList]
  )
  const dispatch = useDispatch()

  const copy = (e, column, value) => {
    if (!column.copy) {
      return
    }
    e.stopPropagation()
    e.preventDefault()
    copyToClipboard(value)
      .then(() => {
        dispatch(
          addToast({
            type: ToastTypes.success,
            title: 'Успех',
            description: `Данные скопированны`,
          })
        )
      })
      .catch((err) => {
        console.log('Something went wrong', err)
      })
  }

  const fetchAgentChange = () => {
    if (!assignAgent) return
    dispatch(
      changeAgent({
        phone: customerPhone,
        agent_id: agentUserNamesWithIds.find((x) => x.username === assignAgent)?.admin_id || '',
        errorAction: () => {
          setShowModal(initialModalState)
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Передача клиента прошла с ошибкой`,
            })
          )
        },
        successAction: () => {
          setShowModal(initialModalState)
          batch(() => {
            dispatch(fetchContracts({ iteration: contracts[type]?.iteration + 1, type, filters }))
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Передача клиента прошла успешно`,
              })
            )
          })
        },
      })
    )
  }

  const handleChangeAgentModal = (phone) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCustomerPhone(phone)
    setShowModal({
      show: true,
      title: 'Перезакрепление клиента.',
      description: 'Выберите агента для перезакрепления',
    })
  }

  const getColorStatus = (status) => {
    let color = 'active'
    if (status === 'loan_overdue') color = 'warning'
    if (status === 'loan_repayment_date') color = 'yellow'
    return color
  }

  const getStatusName = (status) => {
    let name = 'Активен'
    if (status === 'loan_overdue') name = 'Просрочен'
    if (status === 'loan_repayment_date') name = 'День погашения'
    return name
  }

  return (
    <div style={{ overflow: 'auto' }} id='scrollable'>
      <ScrollToTop scrollTo='#scrollable' />
      <StickyBox style={{ zIndex: 9 }}>
        <TableHead structure={HEAD_STRUCTURE} type={type} template={COLUMN_TEMPLATE[type]} />
      </StickyBox>
      <StyledTableWrapper>
        <>
          <InfiniteScroll
            dataLength={dataList?.length || 0}
            next={fetchMoreData}
            hasMore={checkHasMore()}
            loader={
              <StyledInfiniteLoader>
                <Loader />
              </StyledInfiniteLoader>
            }
            scrollableTarget='scrollable'
          >
            {contracts && dataList.length ? (
              dataList?.map((data: IContractListingItem) => {
                const agentName = getAdminFio(data.agent_username, dictData)
                const agentManagerName = getAdminFio(data.agent_manager_username, dictData)

                const contract = {
                  ...data,
                  agent_username: agentName || '',
                  agent_manager_username: agentManagerName || '',
                }

                return (
                  <StaledContainer key={`${contract.loans_loan_id}`}>
                    <>
                      <StyledRowWrapper
                        indicate={+contract.closed_count > 0}
                        onClick={(e) => handleOpenContract(e, contract.loans_loan_id)}
                        template={COLUMN_TEMPLATE[type]}
                        href={`${path}/${contract.loans_loan_id}`}
                      >
                        {BODY_STRUCTURE[type]?.map((column) => {
                          return (
                            <>
                              <StyledValue
                                key={column.id}
                                onClick={(e) => copy(e, column, contract?.loans_loan_number)}
                              >
                                <StyledTitle
                                  pending={false}
                                  styles={column?.styles ? column?.styles : ''}
                                >
                                  {column?.status && (
                                    <>
                                      <StyledStatus
                                        status={getColorStatus(contract.loan_statuses_status_name)}
                                      />
                                      {getStatusName(contract.loan_statuses_status_name)}
                                    </>
                                  )}
                                  {column.value
                                    ? column.value(contract)
                                    : normalizeValues(contract[column.name])}
                                  {column.copy && (
                                    <StyledInfo>
                                      <HiOutlineClipboardCopy />
                                    </StyledInfo>
                                  )}
                                </StyledTitle>
                                {column?.link && (
                                  <StyledCopyLink onClick={(e) => handleCopyLink(e)}>
                                    <CopySuccessSVG />
                                    Ссылка скопирована
                                  </StyledCopyLink>
                                )}
                                {column?.message && (
                                  <StyledMessagesWrapper onClick={(e) => handleOpenComments(e)}>
                                    <MessagesSVG />
                                    <StyledValueCount>5</StyledValueCount>
                                  </StyledMessagesWrapper>
                                )}
                                {column?.createDate && (
                                  <>
                                    <StyledCreateDate>
                                      {formatDate(contract?.loans_loan_date_create)}
                                    </StyledCreateDate>
                                  </>
                                )}
                                {column?.transfer && (
                                  <Button
                                    type='border'
                                    onClick={handleChangeAgentModal(contract?.users_login)}
                                  >
                                    Передать клиента
                                  </Button>
                                )}
                              </StyledValue>
                            </>
                          )
                        })}
                        {mobileItems(contract)[type]}
                      </StyledRowWrapper>
                    </>
                  </StaledContainer>
                )
              })
            ) : (
              <StyledEmptyLabel>
                {!contracts[type]?.pending && <p>Ничего не найдено</p>}
              </StyledEmptyLabel>
            )}
          </InfiniteScroll>
        </>
      </StyledTableWrapper>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={() => setShowModal({ ...showModal, show: false })}
        onClose={() => setShowModal({ ...showModal, show: false })}
        description={showModal.description}
        title={showModal.title}
        buttons={false}
        content={() => (
          <div>
            <StyledSelectAgent>
              <Select
                data={AgentUsernamesList}
                placeholder='Агент'
                value={AgentUsernamesList?.find((v) => v.value === assignAgent) || ''}
                onChange={({ value }) => setAssignAgent(value)}
              />
            </StyledSelectAgent>

            <Button type='standardBig' onClick={fetchAgentChange} disabled={!assignAgent}>
              Перезакрепить
            </Button>
            <Button type='emptyBig' onClick={() => setShowModal(initialModalState)}>
              Отмена
            </Button>
          </div>
        )}
      />
    </div>
  )
}
