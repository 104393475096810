export const INDEX = ''
export const ARM = 'arm'
export const SYSTEM = 'system'
export const CREDIT = 'credit'
export const ACCOUNTANT = 'accountant'
export const UPLOADING = 'unloading'
export const SCORNING = 'scoring'
export const PROTOCOLS = 'protocols'
export const LOANS = 'loans'
export const WAITING = 'waiting'
export const CONTRACTS = 'contracts'
export const CLIENTS = 'clients'
export const LIST = 'list'
export const NBKI = 'nkbi'
export const BAN = 'ban'
export const UNDERWRITER = 'underwriter'
export const AGENT = 'agent'
export const LOAN_CREATE = 'create-loan'
export const SERVICES = 'services'
export const SDL = 'sdl'
export const BAN_LIST = 'ban-list'
export const OPERATOR = 'operator'
export const COLLECTOR = 'collector'
export const SETTINGS = 'settings'
export const DOCUMENTS = 'documents'
export const LIDS = 'lids'

export const getRoute = (pages: string[] | string): string => {
  if (pages instanceof Array) {
    return `/${pages.join('/')}`
  }
  return `/${pages}`
}
