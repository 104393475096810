/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'
import { ICreateDocument } from 'features/settings/types'
import { ServiceBase } from './serviceBase'

export class ProjectSettingService extends ServiceBase {
  protected static SETTINGS_PATH = 'settings'

  public static getDocuments(): AxiosPromise {
    return this.get(`${this.SETTINGS_PATH}/site_documents`).then((response) => response.data)
  }

  public static deleteDocument(documentId: string): AxiosPromise {
    return this.delete(`${this.SETTINGS_PATH}/site_documents/${documentId}`).then(
      (response) => response.data
    )
  }

  public static createDocument(data: ICreateDocument): AxiosPromise {
    return this.post(`${this.SETTINGS_PATH}/site_documents`, data).then((response) => response.data)
  }

  public static updateDocument(data: ICreateDocument, documentId: string): AxiosPromise {
    return this.put(`${this.SETTINGS_PATH}/site_documents/${documentId}`, data).then(
      (response) => response.data
    )
  }

  public static getAgentList(): AxiosPromise {
    return this.get(`${this.SETTINGS_PATH}/agent_assignment`).then((response) => response.data)
  }

  public static assignAdmin(admin_id: string): AxiosPromise {
    return this.post(`${this.SETTINGS_PATH}/agent_assignment?admin_id=${admin_id}`).then(
      (response) => response.data
    )
  }

  public static unassignAdmin(admin_id: string): AxiosPromise {
    return this.delete(`${this.SETTINGS_PATH}/agent_assignment/${admin_id}`).then(
      (response) => response.data
    )
  }
}
