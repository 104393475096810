/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable arrow-body-style */
import { v4 as uuid } from 'uuid'
import { normalizeValues } from 'helpers'

export const BODY_STRUCTURE = (adminsFioData) => {
  return [
    {
      value: (data) => {
        const dataFio = adminsFioData.find((item) => {
          return item?.username === data?.username
        })
        if (!dataFio) return ''

        return `${dataFio?.last_name} ${dataFio?.first_name} ${dataFio?.middle_name}`
      },
      id: uuid(),
      name: 'fio',
    },
    { id: uuid(), name: 'username' },

    {
      value: (data) => {
        return normalizeValues(data?.assigned_at, 'full_date')
      },
      id: uuid(),
      name: 'assigned_at',
    },
    { id: uuid(), action: true },
  ]
}

export const HEAD_STRUCTURE = {
  lids: [
    { title: 'ФИО агента', id: uuid() },
    { title: 'Логин', id: uuid() },
    { title: 'Дата и время в очереди', id: uuid() },
    { title: 'Действия', id: uuid() },
  ],
}

export const MIN_WIDTH = '1500px'

export const COLUMN_TEMPLATE = {
  lids: `
    minmax(100px, 250px) minmax(100px, 1fr) minmax(100px, 1fr)  minmax(100px, 350px)
    `,
}
