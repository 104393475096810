/* eslint-disable arrow-body-style */
import { HiOutlineTrash } from 'react-icons/hi'

import {
  StyledActionDelete,
  StyledActionProvider,
  StyledMobileDescription,
  StyledMobileItemWrapper,
  StyledMobileLoanNumbers,
  StyledMobileSection,
  StyledMobileValue,
} from './Table.styles'
import { normalizeValues } from 'helpers'

export const MobileView: any = ({ agent, handleDeleteModal, dictData }): JSX.Element => {
  const dataFio = dictData?.data?.adminsFioData.find((item) => item?.username === agent?.username)

  return (
    <StyledMobileItemWrapper>
      <StyledMobileSection>
        <StyledMobileDescription>ФИО</StyledMobileDescription>
        <StyledMobileLoanNumbers>{`${dataFio?.last_name} ${dataFio?.first_name} ${dataFio?.middle_name}`}</StyledMobileLoanNumbers>
      </StyledMobileSection>
      <StyledMobileSection>
        <StyledMobileDescription>Логин</StyledMobileDescription>
        <StyledMobileValue>{agent.username}</StyledMobileValue>
      </StyledMobileSection>
      <StyledMobileSection>
        <StyledMobileDescription>Дата и время в очереди</StyledMobileDescription>
        <StyledMobileValue>{normalizeValues(agent?.assigned_at, 'full_date')}</StyledMobileValue>
      </StyledMobileSection>
      <StyledActionProvider>
        <StyledActionDelete
          onClick={() =>
            handleDeleteModal(
              dataFio ? `${dataFio?.last_name} ${dataFio?.first_name} ${dataFio?.middle_name}` : '',
              agent?.admin_id
            )
          }
        >
          <HiOutlineTrash />
        </StyledActionDelete>
      </StyledActionProvider>
    </StyledMobileItemWrapper>
  )
}
