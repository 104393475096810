/* eslint-disable @typescript-eslint/no-unused-vars */
import { v4 as uuid } from 'uuid'
import { LoanType, SdlType } from 'features/loans/types'
import { AgentLoanType, SettingsType } from 'features/agentLoans/types'
import { ContractType } from 'features/contracts/types'
import { CollectorType } from 'features/collector/types'
import { IMenuItems } from './types'

import {
  UNDERWRITER_LOANS_ROUTE,
  AGENT_LOANS_ROUTE,
  SDL_ROUTE_ROUTE,
  CONTRACTS_ITEMS_ROUTE,
  WAITING_LOANS_ROUTE,
  CLIENTS_ROUTE,
  OPERATOR_LOANS_ROUTE,
  COLLECTOR_LOANS_ROUTE,
  SETTINGS as SETTINGS_ROUTE,
  // SETTINGS_ROUTE,
  // SETTINGS_DOCUMENTS_ROUTE,
  // SETTINGS_LIDS_ROUTE,
} from '../../constants'

export const AGENTS: IMenuItems = {
  id: uuid(),
  title: 'Заявки',
  url: `${AGENT_LOANS_ROUTE}`,
  name: 'agents',
  data: [
    {
      title: 'Все',
      id: uuid(),
      url: `/loans?type=${AgentLoanType.allApp}`,
      name: AgentLoanType.allApp,
    },
    // {
    //   title: 'Ожидают анкеты',
    //   id: uuid(),
    //   url: `/loans?type=${AgentLoanType.waiting}`,
    //   name: AgentLoanType.waiting,
    // },
    {
      title: 'Требуются документы',
      id: uuid(),
      url: `/loans?type=${AgentLoanType.requiredDocs}`,
      name: AgentLoanType.requiredDocs,
    },
    {
      title: 'В работе',
      id: uuid(),
      url: `/loans?type=${AgentLoanType.atWork}`,
      name: AgentLoanType.atWork,
    },
    {
      title: 'На проверке',
      id: uuid(),
      url: `/loans?type=${AgentLoanType.checking}`,
      name: AgentLoanType.checking,
    },
    {
      title: 'Проверены',
      id: uuid(),
      url: `/loans?type=${AgentLoanType.checked}`,
      name: AgentLoanType.checked,
    },
  ],
}

export const UNDERWRITER: IMenuItems = {
  id: uuid(),
  title: 'Андеррайтер',
  url: UNDERWRITER_LOANS_ROUTE,
  name: 'underwriter',
  data: [
    {
      title: 'Все',
      id: uuid(),
      url: `/loans?type=${LoanType.all}`,
      name: LoanType.all,
    },
    {
      title: 'Свободные',
      id: uuid(),
      url: `/loans?type=${LoanType.unassigned}`,
      name: LoanType.unassigned,
    },
    {
      title: 'В работе',
      id: uuid(),
      url: `/loans?type=${LoanType.processing}`,
      name: LoanType.processing,
    },
    {
      title: 'Бан-лист',
      id: uuid(),
      url: `/ban-list`,
      name: LoanType.banned,
    },
  ],
}

export const OPERATOR: IMenuItems = {
  id: uuid(),
  title: 'Оператор',
  url: OPERATOR_LOANS_ROUTE,
  name: 'operator',
  data: [
    {
      title: 'Все',
      id: uuid(),
      url: `/loans?type=${LoanType.all}`,
      name: LoanType.all,
    },
  ],
}

export const COLLECTOR: IMenuItems = {
  id: uuid(),
  title: 'Коллектор',
  url: COLLECTOR_LOANS_ROUTE,
  name: 'collector',
  data: [
    {
      title: 'Все',
      id: uuid(),
      url: `/loans?type=${CollectorType.all}`,
      name: CollectorType.all,
    },
    {
      title: 'В работе',
      id: uuid(),
      url: `/loans?type=${CollectorType.inWork}`,
      name: CollectorType.inWork,
    },
    {
      title: 'Свободные',
      id: uuid(),
      url: `/loans?type=${CollectorType.free}`,
      name: CollectorType.free,
    },
  ],
}

export const WAITING: IMenuItems = {
  id: uuid(),
  title: 'Ожидают анкеты',
  url: WAITING_LOANS_ROUTE,
  name: 'waiting',
  data: [],
}

export const CLIENTS: IMenuItems = {
  id: uuid(),
  title: 'Список клиентов',
  url: CLIENTS_ROUTE,
  name: 'clients',
  data: [],
}

export const CONTRACTS: IMenuItems = {
  id: uuid(),
  title: 'Договоры',
  url: CONTRACTS_ITEMS_ROUTE,
  name: 'contracts',
  data: [
    {
      title: 'Все',
      id: uuid(),
      url: `/contracts?type=${ContractType.allContracts}`,
      name: ContractType.allContracts,
    },
    {
      title: 'Активные',
      id: uuid(),
      url: `/contracts?type=${ContractType.active}`,
      name: ContractType.active,
    },
    {
      title: 'Просроченные ',
      id: uuid(),
      url: `/contracts?type=${ContractType.overdue}`,
      name: ContractType.overdue,
    },
    {
      title: 'День погашения ',
      id: uuid(),
      url: `/contracts?type=${ContractType.repayment_date}`,
      name: ContractType.repayment_date,
    },
  ],
}

export const SDL: IMenuItems = {
  id: uuid(),
  title: 'СДЛ',
  url: SDL_ROUTE_ROUTE,
  name: 'sdl',
  data: [],
}

export const SETTINGS: IMenuItems = {
  id: uuid(),
  title: 'Настройки',
  url: `/settings/documents`,
  name: 'settings',
  data: [
    {
      title: 'Документы сайт',
      id: uuid(),
      url: `/settings/documents`,
      name: SettingsType.documents,
    },
    {
      title: 'Управление лидами',
      id: uuid(),
      url: `/settings/lids`,
      name: SettingsType.lids,
    },
  ],
}
