/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components/macro'
import { THEME } from 'core/theme'

const STYLED_OPTIONS = {
  styledValuePhone: {
    color: THEME.COLORS.SUCCESS,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  redValueVerification: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: THEME.COLORS.WARNING,
  },
  boldGreen: {
    color: THEME.COLORS.SUCCESS,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  error: {
    color: THEME.COLORS.WARNING,
    fontSize: '16px',
    fontWeight: 'bold',
  },
}

export const StyledModuleWrapper = styled.div<{
  isOpen?: boolean
  editable?: boolean
  isEdited?: boolean
}>`
  display: grid;
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  padding: 0;
  transition: height 0.5s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: ${THEME.COLORS.WHITE};
      border-radius: 3px;
      transition: 0.3s;

      ${StyledContentWrapper} {
        max-height: 10000px;
        margin-top: 15px;
        opacity: 1;
        transform: translateY(0);
        transition: 0.5s;
        padding-bottom: 25px;
      }
      ${StyledHeaderModule} {
        background: #fafbfd;
        border-bottom: 1px solid ${THEME.COLORS.SMOOTH};
      }
      ${StyledButtonBlock} {
        margin: 20px 0;
      }
    `}

  ${({ editable }) =>
    editable &&
    css`
      ${StyledEditButton} {
        & svg path {
          fill: ${THEME.COLORS.BLUE};
        }
      }
    `}

    ${({ isEdited, isOpen }) =>
    isEdited &&
    !isOpen &&
    css`
      ${StyledHeaderModule} {
        &:hover {
          background-color: ${THEME.COLORS.YELLOW_HOVER};
        }
        background-color: ${THEME.COLORS.YELLOW};
      }
    `}
`

export const StyledContentWrapper = styled.div`
  margin-top: 0;
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
  transform: translateY(-1rem);
  transition: 0.3s;
  position: relative;
  transition: height 0.5s;
  padding-bottom: 0;
  overflow-x: auto;
`

export const StyledHeaderModule = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  background-color: ${THEME.COLORS.SMOOTH};
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 1px solid transparent;

  &:hover {
    background-color: ${THEME.COLORS.SMOOTH};
  }
`

export const StyledEditButton = styled.div`
  margin-bottom: 10px;
  display: flex;
  padding: 0 32px;
  justify-content: flex-end;

  & svg {
    cursor: pointer;
  }
`

export const StyledButtonBlock = styled.div`
  margin: 0;
  padding: 0 32px;
  transition: 0.3s;
`

export const StyledActions = styled.div<any>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &:before {
    content: '+';
    display: block;
    position: absolute;
    left: -18px;
    font-weight: 900;
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      &:before {
        content: '-';
        display: block;
        position: absolute;
        left: -18px;
        font-weight: 900;
      }
    `}
`

export const StyledColumn = styled.div<{
  column?: number
  withoutBorder?: boolean
  correspond?: boolean
}>`
  grid-column: span ${(props) => props.column || 1};
  border-bottom: 1px dotted ${THEME.COLORS.TABLE_BORDER};
  padding-bottom: 5px;

  ${({ withoutBorder }) =>
    withoutBorder &&
    css`
      border-bottom: 1px dotted transparent;
    `}
  ${({ correspond }) =>
    correspond &&
    css`
      p:last-child {
        color: ${THEME.COLORS.SUCCESS};
      }
    `}
`

export const StyledTitle = styled.p<any>`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
`
export const StyledValue = styled.p<{ styles?: string; alarm?: boolean } | any>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${THEME.COLORS.DARK_BLUE};

  ${({ styles }) =>
    styles &&
    css`
      ${STYLED_OPTIONS[styles]}
    `}
  ${({ alarm }) =>
    alarm &&
    css`
      > div {
        color: red;
      }
    `}

  @media (max-width: 480px) {
    font-size: 14px;
  }
`

export const StyledContent = styled.div<{ isFetching?: boolean } | any>`
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  gap: 30px 30px;
  padding: 0 32px;

  ${({ isFetching }) =>
    isFetching &&
    css`
      max-height: 300px;
    `}

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);

    & div {
      grid-column: span 1;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    gap: 10px 10px;
  }
`

export const StyledLoaderWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLoaderScreen = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${THEME.COLORS.WHITE};
  z-index: 9;
`

export const StyledArrow = styled.div<{ isOpen?: boolean }>`
  margin-left: 32px;

  & svg {
    transform: rotate(0);
    transition: 0.3s;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        transform: rotate(180deg);
        & path {
          fill: ${THEME.COLORS.BLUE};
        }
      }
    `}
`

export const StyledHeadTitle = styled.p<any>`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${THEME.COLORS.DARK_BLUE};
  padding: 15px 0;

  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 15px;
  }
`

export const StyledTableColumn = styled.div<{
  column?: number
  withoutBorder?: boolean
  correspond?: boolean
}>`
  position: relative;
  grid-column: span ${(props) => props.column || 1};
  padding-bottom: 5px;
`

export const StyledTableValue = styled.p<any>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #08003a;
  padding: 15px;
  border-top: 1px solid ${THEME.COLORS.BORDER_LOAN};
`

export const StyledTableTitle = styled.p<any>`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 13px 15px;
  color: #828282;
`

export const StyledImageLoaderWrapper = styled.div<any>`
  width: 190px;
  height: 145px;
  border: 1px solid ${THEME.COLORS.BORDER_LOAN};
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-right: 10px;
`

export const StyledImage = styled.div<{ fullSize?: boolean } | any>`
  width: 190px;
  height: 145px;
  border: 1px solid ${THEME.COLORS.BORDER_LOAN};
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  & img {
    object-fit: contain;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

export const StyledDeleteImage = styled.div<any>`
  position: absolute;
  right: 6px;
  top: 6px;
  background: ${THEME.COLORS.WHITE};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-size: 17px;
  cursor: pointer;
  font-weight: bold;

  justify-content: center;
  line-height: 1;
  color: gray;
  border: 1px solid lightgray;
  &:hover {
    border: 1px solid gray;
    color: #030303;
  }
`

export const StyledImagesBlock = styled.div<any>`
  padding: 0 32px;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
      > div {
        display: flex;
      }
    `};

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    width: 100%;
  }
`

export const StyledImageWrapper = styled.div<any>`
  width: fit-content;
  margin-right: 10px;
  position: relative;
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `};
  input {
    display: none;
  }

  @media (max-width: 480px) {
    max-width: 48%;
    width: 48%;
    margin-right: 0;
  }
`

export const StyledImageDate = styled.div`
  font-size: 12px;
  width: fit-content;
  margin-right: 10px;
  position: absolute;
  background: white;
  border-radius: 5px;
  padding: 0 5px;
  font-weight: bold;
  bottom: 3px;
  left: 50px;
  border: 1px solid #d9d9d9;
  box-shadow: 1px 1px 1px #d9d9d9;
`

export const StyledPhotoItem = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 480px) {
    row-gap: 10px;
    justify-content: space-between;
  }
`

export const StyledPhotoWrapper = styled.div`
  width: 100%;
  border-right: 1px solid #dfe0eb;
  margin-right: 20px;

  @media (max-width: 480px) {
    width: 100%;
    border-right: 1px solid transparent;
    margin-right: 0;
  }
`
