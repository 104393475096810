/* eslint-disable prefer-const */
export const CreditProduct = {
  pdl: 'pdl',
  pdl_08: 'pdl_08',
  installment: 'installment',
  installment_9k: 'installment_9k',
  installment_2: 'installment_2',
  installment_3: 'installment_3',
  installment_20_30_1: 'installment_20_30_1',
  installment_11_19k: 'installment_11_19k',
  installment_30_50k: 'installment_30_50k',
  installment_30_50k_048_52w: 'installment_30_50k_048_52w',
  installment_30_40k_05_36w: 'installment_30_40k_05_36w',
  installment_30_100k: 'installment_30_100k',
  installment_30_50k_distant: 'installment_30_50k_distant',
  installment_30_100k_distant: 'installment_30_100k_distant',
  installment_20_30k_distant: 'installment_20_30k_distant',
  installment_30_50k_054_52w: 'installment_30_50k_054_52w',
  installment_20_30: 'installment_20_30',
  installment_30_40k_055_36w: 'installment_30_40k_055_36w',
  installment_30_50k_054_52w_limit: 'installment_30_50k_054_52w_limit',
  installment_16_30k_08_25w: 'installment_16_30k_08_25w',
  installment_31_50k_073_25w: 'installment_31_50k_073_25w',
  installment_30_100k_038_52w: 'installment_30_100k_038_52w',
  installment_16_30k_08_25w_agent: 'installment_16_30k_08_25w_agent',
  bankrupt_30_100k_05_52w: 'bankrupt_30_100k_05_52w',
  bankrupt_30_100k_055_52w: 'bankrupt_30_100k_055_52w',
  bankrupt_30_50k_08_24w: 'bankrupt_30_50k_08_24w',
  bankrupt_20_30k_08_12w: 'bankrupt_20_30k_08_12w',
  bankrupt_30_100k_038_52w: 'bankrupt_30_100k_038_52w',
  bankrupt_20_30k_08_12w_agent: 'bankrupt_20_30k_08_12w_agent',
}

interface ClassifiedProducts {
  bankruptProducts: string[]
  commonProducts: string[]
}

export function classifyProducts(): ClassifiedProducts {
  let bankruptProducts: string[] = []
  let commonProducts: string[] = []

  for (let key in CreditProduct) {
    if (key.startsWith('bankrupt')) {
      bankruptProducts.push(key)
    } else {
      commonProducts.push(key)
    }
  }

  return { bankruptProducts, commonProducts }
}

export const CreditProductTranslate = {
  pdl: 'PDL',
  pdl_08: 'PDL_0.8',
  installment_11_19k: 'Аннуитет 11-19k',
  installment_30_50k: 'Аннуитет 30-50k',
  installment_30_50k_048_52w: 'Аннуитет 30-50k 0.48 52 Лимит',
  installment_30_40k_05_36w: 'Аннуитет 30-40k 0.5 36 Лимит',
  installment_30_100k: 'Аннуитет 30-100k',
  installment: 'Аннуитет',
  installment_2: 'Аннуитет 2',
  installment_3: 'Аннуитет 3',
  installment_20_30: 'Аннуитет 20-30k',
  installment_20_30_1: 'Аннуитет 20-30k-1%',
  installment_9k: 'Аннуитет 9k',
  installment_30_50k_distant: 'Аннуитет 30-50k Дистант',
  installment_30_100k_distant: 'Аннуитет 30-100k Дистант',
  installment_20_30k_distant: 'Аннуитет 20-30k Дистант',
  installment_30_50k_054_52w: 'Аннуитет 30-50k 0.54 52',
  installment_30_40k_055_36w: 'Аннуитет 30-40k 0.55 36',
  installment_30_50k_054_52w_limit: 'Аннуитет 30-50k 0.54 52 Лимит',

  installment_16_30k_08_25w: 'Аннуитет 16-30k 0.8 25',
  installment_31_50k_073_25w: 'Аннуитет 31-50k 0.73 25',
  installment_30_100k_038_52w: 'Аннуитет 30-100k 0.38 52',
  installment_16_30k_08_25w_agent: 'Аннуитет 16-30k 0.8 25 агент',

  bankrupt_30_100k_05_52w: 'Банкрот 30-100k 0.5 52 Лимит',
  bankrupt_30_100k_055_52w: 'Банкрот 30-100k 0.55 52',
  bankrupt_30_50k_08_24w: 'Банкрот 30-50k 0.8 24',
  bankrupt_20_30k_08_12w: 'Банкрот 20-30k 0.8 12',
  bankrupt_30_100k_038_52w: 'Банкрот 30-100k 0.38 52',
  bankrupt_20_30k_08_12w_agent: 'Банкрот 20-30k 0.8 12 агент',
}

export const CreditProductCodes = {
  '6040e8d9-68d0-4466-a921-6864dbe592f3': CreditProduct.pdl,
  '48a7bf2b-81e2-4d85-a8c8-2b0be86803ec': CreditProduct.pdl_08,
  'fcc45715-bc85-4731-8451-50b0be30962d': CreditProduct.installment,
  '1d4e88ec-771d-46f9-82c3-edbed1d3a8fa': CreditProduct.installment_2,
  '25c11318-24fd-40a8-bf35-bb0bd5ac743b': CreditProduct.installment_3,
  '532fe66f-67d2-4ceb-9c49-debc87420cd4': CreditProduct.installment_20_30,
  '7fbf8c4c-4f33-40b6-b1b5-2d0be0ef7216': CreditProduct.installment_20_30_1,
  'e9ac8d83-ca05-4344-bd14-9ebec98c1f85': CreditProduct.installment_9k,
  'c4d62516-54ac-4644-b258-14a9d6838f14': CreditProduct.installment_11_19k,
  '20ed66aa-39c0-4426-a872-8027e5090211': CreditProduct.installment_30_50k,
  '1161b178-1f9b-481c-9146-fefd3f7b7d4a': CreditProduct.installment_30_100k,
  '6a78396d-5153-4287-b6d1-18264b4090c4': CreditProduct.installment_20_30k_distant,
  '8385654e-8c92-4c31-920e-f4bdc5227e2c': CreditProduct.installment_30_100k_distant,
  '2a4a50c7-bd97-4e8c-99b9-0fa0a9c8366e': CreditProduct.installment_30_50k_distant,
  '20b225f8-baf4-44cf-8346-afd074ceafaa': CreditProduct.installment_30_50k_054_52w,
  '9b378652-a04c-4586-a0fb-6a78b54e6402': CreditProduct.installment_30_40k_055_36w,
  '38a468d8-4b28-45ec-903e-5be10e06b1a1': CreditProduct.installment_30_40k_05_36w,
  '0e07e1cb-58db-4149-aefc-e3e7729da4d1': CreditProduct.installment_30_50k_048_52w,
  '86b4c823-5d08-46c1-bdb7-39a2c4efcebc': CreditProduct.installment_30_50k_054_52w_limit,

  'a854f1cb-d03e-4312-b97e-5ec14cf7ffe7': CreditProduct.installment_16_30k_08_25w,
  '4b3c24fd-e856-47d8-a9fb-598b2f35a98f': CreditProduct.installment_31_50k_073_25w,
  '27ceb2ef-d625-4098-911b-4f45bf5ad700': CreditProduct.installment_30_100k_038_52w,
  '2575a4a4-f7ea-43b0-8816-7940053ab571': CreditProduct.installment_16_30k_08_25w_agent,

  'a7cdf4d3-d114-4024-95ef-c19e274dd702': CreditProduct.bankrupt_30_100k_055_52w,
  'bdd754a2-35f4-43b6-93dd-eabbc1f30f04': CreditProduct.bankrupt_30_50k_08_24w,
  'b952c85b-53c7-403d-91dc-e2d4665cc6b9': CreditProduct.bankrupt_30_100k_05_52w,
  'c08c650a-4ae4-4c44-9ea9-e4cb66c1fa82': CreditProduct.bankrupt_20_30k_08_12w,
  'df1f1a5d-910f-47ef-976f-2ebcfd6fae73': CreditProduct.bankrupt_30_100k_038_52w,
  '1e86192e-60ef-428d-96bb-1aeb0313cac9': CreditProduct.bankrupt_20_30k_08_12w_agent,
}
