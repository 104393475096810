/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchDocumentsList, getAgentList, assignAdmin, unassignAgent } from './extra'

const initialState: any = {
  isFetching: false,
  data: [],
  isFetchingAgents: false,
  dataAgents: [],
  isFetchingAgentAssign: false,
  isFetchingAgentUnassign: false,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocumentsList.pending, (state) => {
        state.isFetching = true
      })
      .addCase(fetchDocumentsList.rejected, (state) => {
        state.isFetching = false
      })
      .addCase(fetchDocumentsList.fulfilled, (state, { payload }) => {
        state.isFetching = false
        state.data = payload.data
      })

      // dataAgents
      .addCase(getAgentList.pending, (state) => {
        state.isFetchingAgents = true
      })
      .addCase(getAgentList.rejected, (state) => {
        state.isFetchingAgents = false
      })
      .addCase(getAgentList.fulfilled, (state, { payload }) => {
        state.isFetchingAgents = false
        state.dataAgents = payload.data
      })
      .addCase(assignAdmin.pending, (state) => {
        state.isFetchingAgentAssign = true
      })
      .addCase(assignAdmin.rejected, (state) => {
        state.isFetchingAgentAssign = false
      })
      .addCase(assignAdmin.fulfilled, (state) => {
        state.isFetchingAgentAssign = false
      })

      .addCase(unassignAgent.pending, (state) => {
        state.isFetchingAgentUnassign = true
      })
      .addCase(unassignAgent.rejected, (state) => {
        state.isFetchingAgentUnassign = false
      })
      .addCase(unassignAgent.fulfilled, (state) => {
        state.isFetchingAgentUnassign = false
      })
  },
})

export default settingsSlice.reducer
