/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectSettingService } from 'services/settingsService'
import { ICreateDocument } from './types'

export const fetchDocumentsList = createAsyncThunk<any>('settings/fetchDocumentsList', async () => {
  const response = await ProjectSettingService.getDocuments().then((data) => ({
    data,
  }))
  return response
})

export const deleteDocumentsList = createAsyncThunk<
  any,
  { documentId: string; successAction: () => void; errorAction: () => void }
>('settings/deleteDocumentsList', async (props) => {
  const { documentId, successAction, errorAction } = props
  const response = await ProjectSettingService.deleteDocument(documentId)
    .then((data) => {
      successAction()
      return data
    })
    .catch(() => {
      errorAction()
    })
  return response
})

export const createDocument = createAsyncThunk<
  any,
  { data: ICreateDocument; successAction: () => void; errorAction: () => void }
>('settings/createDocument', async (props) => {
  const { data, successAction, errorAction } = props
  const response = await ProjectSettingService.createDocument(data)
    .then((respData) => {
      successAction()
      return respData
    })
    .catch(() => {
      errorAction()
    })
  return response
})

export const updateDocument = createAsyncThunk<
  any,
  { data: ICreateDocument; documentId: string; successAction: () => void; errorAction: () => void }
>('settings/updateDocument', async (props) => {
  const { data, documentId, successAction, errorAction } = props
  const response = await ProjectSettingService.updateDocument(data, documentId)
    .then((respData) => {
      successAction()
      return respData
    })
    .catch(() => {
      errorAction()
    })
  return response
})

export const getAgentList = createAsyncThunk<any>('settings/getAgentList', async () => {
  const response = await ProjectSettingService.getAgentList().then((data) => ({
    data,
  }))
  return response
})

export const assignAdmin = createAsyncThunk<
  any,
  { admin_id: string; successAction: () => void; errorAction: () => void }
>('settings/addAdmin', async (props) => {
  const { admin_id, successAction, errorAction } = props
  const response = await ProjectSettingService.assignAdmin(admin_id)
    .then((respData) => {
      successAction()
      return respData
    })
    .catch(() => {
      errorAction()
    })
  return response
})

export const unassignAgent = createAsyncThunk<
  any,
  { admin_id: string; successAction: () => void; errorAction: () => void }
>('settings/unassignAgent', async (props) => {
  const { admin_id, successAction, errorAction } = props
  const response = await ProjectSettingService.unassignAdmin(admin_id)
    .then((data) => {
      successAction()
      return data
    })
    .catch(() => {
      errorAction()
    })
  return response
})
