/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import StickyBox from 'react-sticky-box'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'

import { GlobalState } from 'index'
import { getAdminFio } from 'helpers'

import {
  EmptyValueReplacer,
  ScrollToTop,
  TableHead,
  ModalConfirm,
  Select,
  Button,
} from 'components'

import { MobileView } from './MobileView'

import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { fetchAgentWaitingLoans, removeFromAgent } from 'features/agentLoans/extra'
import { useGetAgentUserNamesWithIds } from 'features/dict/dictSelectors'
import { assignAdmin, getAgentList, unassignAgent } from 'features/settings/extra'

import {
  StyledRowWrapper,
  StyledTitle,
  StyledValue,
  StyledActionProvider,
  StyledActionDelete,
  StyledSelectAgent,
} from './Table.styles'

import { StaledContainer, StyledEmptyLabel, StyledTableWrapper } from '../Setting.styles'
import { BODY_STRUCTURE, COLUMN_TEMPLATE, HEAD_STRUCTURE } from './tableStructure'

import { HiOutlineTrash } from 'react-icons/hi'

export const Table: any = ({
  dataAgents,
  showModal,
  setShowModal,
  initialModalState,
  handleAddAgentModal,
  assignAgent,
  setAssignAgent,
}): JSX.Element => {
  const dictData = useSelector((state: GlobalState) => state.dict)
  const settings = useSelector((state: GlobalState) => state.settings)
  const dispatch = useDispatch()

  const agentUserNamesWithIds = useGetAgentUserNamesWithIds() || []

  const AgentUsernamesList = agentUserNamesWithIds.map((value) => ({
    label: getAdminFio(value.username, dictData),
    value: value.username,
  }))

  const ActionProvider = (props) => {
    const { username, admin_id } = props

    const dataFio = dictData?.data?.adminsFioData.find((item) => item?.username === username)

    return (
      <StyledActionProvider>
        <StyledActionDelete
          onClick={() =>
            handleDeleteModal(
              dataFio ? `${dataFio?.last_name} ${dataFio?.first_name} ${dataFio?.middle_name}` : '',
              admin_id
            )
          }
        >
          <HiOutlineTrash />
        </StyledActionDelete>
      </StyledActionProvider>
    )
  }

  const handleDeleteModal = (username, admin_id): void => {
    setShowModal({
      admin_id,
      show: true,
      title: 'Удаление агента.',
      description: `Удалить агента ${username}?`,
      variant: 'delete',
    })
  }

  const fetchDeleteAgent = () => {
    dispatch(
      unassignAgent({
        admin_id: showModal.admin_id,
        errorAction: () => {
          setShowModal(initialModalState)
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Удаление произошло с ошибкой`,
            })
          )
        },
        successAction: () => {
          setShowModal(initialModalState)
          batch(() => {
            dispatch(getAgentList())
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Удаление прошло успешно`,
              })
            )
          })
        },
      })
    )
  }

  const fetchAddAgent = () => {
    const agentData = dictData?.data?.agentList.find((item) => item?.username === assignAgent)
    if (!agentData) return

    dispatch(
      assignAdmin({
        admin_id: agentData?.admin_id,
        errorAction: () => {
          setShowModal(initialModalState)
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Добавление произошло с ошибкой`,
            })
          )
        },
        successAction: () => {
          setShowModal(initialModalState)
          batch(() => {
            dispatch(getAgentList())
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Агент успешно добвлен`,
              })
            )
          })
        },
      })
    )
  }

  return (
    <div style={{ overflow: 'auto' }} id='scrollable'>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={() => setShowModal({ ...showModal, show: false })}
        onClose={() => setShowModal({ ...showModal, show: false })}
        description={showModal.description}
        title={showModal.title}
        buttons={false}
        content={() => (
          <>
            {showModal.variant === 'delete' ? (
              <>
                <div>
                  <Button
                    type='standardBig'
                    onClick={fetchDeleteAgent}
                    disabled={settings?.isFetchingAgentUnassign}
                    pending={settings?.isFetchingAgentUnassign}
                  >
                    Удалить
                  </Button>
                  <Button type='emptyBig' onClick={() => setShowModal(initialModalState)}>
                    Отмена
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <StyledSelectAgent>
                    <Select
                      data={AgentUsernamesList}
                      placeholder='Агент'
                      value={AgentUsernamesList?.find((v) => v.value === assignAgent) || ''}
                      onChange={({ value }) => setAssignAgent(value)}
                    />
                  </StyledSelectAgent>
                  <Button
                    type='standardBig'
                    onClick={fetchAddAgent}
                    disabled={!assignAgent || settings?.isFetchingAgentAssign}
                    pending={settings?.isFetchingAgentAssign}
                  >
                    Добавить
                  </Button>
                  <Button type='emptyBig' onClick={() => setShowModal(initialModalState)}>
                    Отмена
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      />
      <ScrollToTop scrollTo='#scrollable' />
      <StickyBox>
        <TableHead structure={HEAD_STRUCTURE} type='lids' template={COLUMN_TEMPLATE.lids} />
      </StickyBox>
      <StyledTableWrapper>
        <>
          {dataAgents.length ? (
            dataAgents?.map((agent: any, i) => (
              <StaledContainer key={`${agent.admin_id}-${i}`}>
                <>
                  <StyledRowWrapper template={COLUMN_TEMPLATE.lids} hover={false}>
                    {BODY_STRUCTURE(dictData?.data?.adminsFioData).map((column) => (
                      <StyledValue key={column.id} onClick={null}>
                        <StyledTitle pending={agent.pending}>
                          {column?.action && <ActionProvider {...agent} />}
                          {column.value
                            ? column.value(agent)
                            : agent[column.name] || (column.action ? null : <EmptyValueReplacer />)}
                        </StyledTitle>
                      </StyledValue>
                    ))}
                    <MobileView
                      agent={agent}
                      handleAddAgentModal={handleAddAgentModal}
                      handleDeleteModal={handleDeleteModal}
                      dictData={dictData}
                    />
                  </StyledRowWrapper>
                </>
              </StaledContainer>
            ))
          ) : (
            <StyledEmptyLabel>{!dataAgents?.pending && <p>Ничего не найдено</p>}</StyledEmptyLabel>
          )}
        </>
      </StyledTableWrapper>
    </div>
  )
}
