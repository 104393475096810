/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchExternalScoringResult } from 'services/scoringService'
import { ProjectLoansService } from '../../services/loanService'
import {
  IAdditionalContactsPayload,
  IBankruptDataItems,
  IPassportData,
  IPassportDataUpdate,
  IPersonalData,
  IPersonalDataUpdate,
  IRegistrationDataUpdate,
  IResidenceDataUpdate,
  IUserDetailsUpdateData,
} from './types'

export const fetchLoanPageById = createAsyncThunk<any, { id }>(
  'singleLoan/loadPageById',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getLoanPageById(id).then(({ data }) => data)
    return response
  }
)

export const fetchVideoVerification = createAsyncThunk<any, { customer_id }>(
  'singleLoan/fetchVideoVerification',
  async (props) => {
    const { customer_id } = props
    const response = await ProjectLoansService.getVideoVerification(customer_id).then(
      ({ data }) => data
    )
    return response
  }
)

export const deleteVideoVerification = createAsyncThunk<
  any,
  { video_id: string; onError: () => void; onSuccess: () => void }
>('singleLoan/deleteVideoVerification', async (props) => {
  const { video_id, onSuccess, onError } = props
  const response = await ProjectLoansService.deleteVideoVerification(video_id)
    .then(({ data }) => {
      onSuccess()
      return data
    })
    .catch((e) => {
      onError()
      throw new Error(e)
    })
  return response
})

export const fetchPersonalDataPhoto = createAsyncThunk<any, { id }>(
  'singleLoan/loadPersonalPhoto',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPersonalDataPhoto(id).then(({ data }) => data)
    return response
  }
)

export const fetchUserDetailsData = createAsyncThunk<any, { id }>(
  'singleLoan/loadUserDetails',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getDetailsUserData(id).then(({ data }) => data)
    return response
  }
)

export const fetchRegistrationDataPhoto = createAsyncThunk<any, { id }>(
  'singleLoan/loadRegistrationPhoto',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getRegistrationDataPhoto(id).then(({ data }) => data)
    return response
  }
)

export const fetchPersonalData = createAsyncThunk<any, { id }>(
  'singleLoan/loadPersonalData',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPersonalData(id).then(({ data }) => data)
    return response
  }
)

// export const fetchFemidaPhone = createAsyncThunk<any, { id; number; force }>(
//   'singleLoan/femidaPhone',
//   async (props) => {
//     const { id, number, force } = props
//     const response = await ProjectLoansService.getFemidaPhone(id, number, force).then(
//       ({ data }) => data
//     )
//     return response
//   }
// )

export const fetchFemidaPhone = createAsyncThunk<
  any,
  {
    data: { lastname; firstname; middlename; birthday; phone_number }
    userId
    number
    force
  }
>('singleLoan/femidaPhone', async (props) => {
  const response = await ProjectLoansService.getFemidaPhone(props).then(({ data }) => data)
  return response
})
export const fetchFemidaPassport = createAsyncThunk<
  any,
  {
    data: { lastname; firstname; middlename; birthday; passport_series_and_number }
    userId
    number
    force
  }
>('singleLoan/femidaPassport', async (props) => {
  const response = await ProjectLoansService.getFemidaPassport(props).then(({ data }) => data)
  return response
})

export const fetchFemidaScoringLegal = createAsyncThunk<
  any,
  {
    data: { lastname; firstname; middlename; birthday; phone_number }
    userId
    number
    force
  }
>('singleLoan/femidaScoringLegal', async (props) => {
  const response = await ProjectLoansService.getFemidaScoringLegal(props).then(({ data }) => data)
  return response
})

export const fetchFemidaScoringOneTimePhone = createAsyncThunk<
  any,
  {
    data: { phone_number }
    userId
    number
    force
  }
>('singleLoan/femidaScoringOneTimePhone', async (props) => {
  const response = await ProjectLoansService.getFemidaScoringOneTimePhone(props).then(
    ({ data }) => data
  )
  return response
})
export const fetchFemidaScoringFraudPhone = createAsyncThunk<
  any,
  {
    data: { phone_number }
    userId
    number
    force
  }
>('singleLoan/femidaScoringFraudPhone', async (props) => {
  const response = await ProjectLoansService.getFemidaScoringFraudPhone(props).then(
    ({ data }) => data
  )
  return response
})

export const fetchLoanRatingResultData = createAsyncThunk<any, { operationToken; userId }>(
  'singleLoan/loanRatingResultData',
  async ({ userId, operationToken }) => {
    const response = await ProjectLoansService.getLoanRatingResultData({
      userId,
      operationToken,
    }).then(({ data }) => data)
    return response
  }
)

export const fetchFsspData = createAsyncThunk<
  any,
  {
    data: { last_name; first_name; mid_name; birth_date }
    userId
    number
    force
  }
>('singleLoan/loadFsspData', async (props) => {
  const response = await ProjectLoansService.getFsspData(props).then(({ data }) => data)
  return response
})

export const fetchBkiDocuments = createAsyncThunk<
  any,
  {
    userId
  }
>('singleLoan/getBkiDocuments', async (props) => {
  const response = await ProjectLoansService.getBkiDocuments(props).then(({ data }) => data)
  return response
})

export const fetchLoanRatingData = createAsyncThunk<
  any,
  {
    data: {
      last_name
      first_name
      mid_name
      birth_date
      passport_number
      issueDate
      issueCountry
      birthPlace
      issueAuthority
    }
    userId
    number
  }
>('singleLoan/loadLoanRatingData', async (props) => {
  const response = await ProjectLoansService.getLoanRatingData(props).then(({ data }) => data)
  return response
})

export const updatePersonalData = createAsyncThunk<
  any,
  {
    id
    body: IPersonalDataUpdate
    values: IPersonalData
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/updatePersonalData', async (props) => {
  const { id, body, onSuccess, onError, values } = props
  const response = await ProjectLoansService.updatePersonalData(id, body)
    .then(() => {
      onSuccess && onSuccess()
      const { time_zone, marital_status, email } = values
      return { time_zone, marital_status, email }
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const fetchAutoCheckData = createAsyncThunk<any, { id }>(
  'singleLoan/loadAutoCheckData',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getAutoCheck(id).then(({ data }) => data)
    return response
  }
)

export const fetchPassportData = createAsyncThunk<any, { id }>(
  'singleLoan/loadPassportData',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPassportData(id).then(({ data }) => data)
    return response
  }
)

export const checkVerifyPassportData = createAsyncThunk<any, { loanId }>(
  'singleLoan/checkVerifyPassportData',
  async (props) => {
    const { loanId } = props
    const response = await ProjectLoansService.getVerifyPassportData(loanId).then(
      ({ data }) => data
    )
    return response
  }
)

export const changeVerifyPassportData = createAsyncThunk<
  any,
  {
    loanId
    status
    onSuccess?: () => void
  }
>('singleLoan/updatePassportData', async (props) => {
  const { loanId, onSuccess, status } = props
  const response = await ProjectLoansService.changeVerifyPassportData({ loanId, status })
    .then(() => {
      onSuccess && onSuccess()
    })
    .catch((e) => {
      throw new Error(e)
    })
  return response
})

export const updatePassportData = createAsyncThunk<
  any,
  {
    id
    body: IPassportDataUpdate
    values: IPassportData | any
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/updatePassportData', async (props) => {
  const { id, body, onSuccess, onError, values } = props
  const response = await ProjectLoansService.updatePassportData(id, body)
    .then(() => {
      onSuccess && onSuccess()
      return values
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const updateRegistrationData = createAsyncThunk<
  any,
  {
    id
    body: IRegistrationDataUpdate
    values: IRegistrationDataUpdate
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/updateRegistrationData', async (props) => {
  const { id, body, onSuccess, onError, values } = props

  const response = await ProjectLoansService.updateRegistrationData(id, body)
    .then(() => {
      onSuccess && onSuccess()
      return values
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const updateResidenceData = createAsyncThunk<
  any,
  {
    id
    body: IResidenceDataUpdate
    values: IResidenceDataUpdate
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/updateResidenceData', async (props) => {
  const { id, body, onSuccess, onError, values } = props
  const response = await ProjectLoansService.updateResidenceData(id, body)
    .then(() => {
      onSuccess && onSuccess()
      return values
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const updateUserDetailsData = createAsyncThunk<
  any,
  {
    id
    body: IUserDetailsUpdateData
    values: IUserDetailsUpdateData
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/updateDetailsData', async (props) => {
  const { id, body, onSuccess, onError, values } = props
  const response = await ProjectLoansService.updateDetailsData(id, body)
    .then(() => {
      onSuccess && onSuccess()
      return values
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const fetchBankCardData = createAsyncThunk<any, { id }>(
  'singleLoan/loadBankCardData',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getBankCardData(id).then(({ data }) => data)
    return response
  }
)

export const deleteBankCardData = createAsyncThunk<
  any,
  { id; successAction: () => void; errorAction: () => void }
>('singleLoan/deletedBankCardData', async (props) => {
  const { id, successAction, errorAction } = props
  const response = await ProjectLoansService.deleteBankCardData(id)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch((e) => errorAction())
  return response
})

export const fetchPhonesData = createAsyncThunk<any, { id }>(
  'singleLoan/loadPhonesData',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPhonesData(id).then(({ data }) => data)
    return response
  }
)

export const fetchAnalyzeData = createAsyncThunk<any, { userId; loanId }>(
  'singleLoan/loadAnalyzeData',
  async (props) => {
    const { userId, loanId } = props
    const response = await ProjectLoansService.getAnalyzeData(userId, loanId).then(
      ({ data }) => data
    )
    return response
  }
)

export const fetchBankCardDataPhoto = createAsyncThunk<any, { id }>(
  'singleLoan/loadBankCardPhoto',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getBankCardDataPhoto(id).then(({ data }) => data)
    return response
  }
)

export const fetchAgentDataPhoto = createAsyncThunk<any, { id }>(
  'singleLoan/loadAgentPhoto',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getAgentDataPhoto(id).then(({ data }) => data)
    return response
  }
)

export const fetchIsBanned = createAsyncThunk<any, { id }>(
  'singleLoan/loadIsBanned',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getIsBanned(id).then(({ data }) => data)
    return response
  }
)

export const fetchOnBan = createAsyncThunk<any, { id; options }>(
  'singleLoan/fetchOnBan',
  async (props) => {
    const { id, options } = props
    const response = await ProjectLoansService.onBan(id, options).then(({ data }) => data)
    return response
  }
)

export const fetchOnUnban = createAsyncThunk<any, { id }>(
  'singleLoan/fetchOnUnban',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.onUnban(id).then(({ data }) => data)
    return response
  }
)
export const fetchUnAssignLoan = createAsyncThunk<any, { id: string }>(
  'singleLoan/unassignLoan',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.setUnassign(id).then(({ data }) => data)
    return response
  }
)

export const sendLoanToAutomaticCheck = createAsyncThunk<
  any,
  { id; timeLimit; sumLimit; comment; status_name; onSuccess; onError }
>('singleLoan/sendLoanToAutomaticCheck', async (props) => {
  const { id, timeLimit, sumLimit, comment, status_name, onSuccess, onError } = props
  const response = await ProjectLoansService.changeStatus(
    id,
    timeLimit,
    sumLimit,
    comment,
    status_name
  )
    .then((data) => {
      onSuccess && onSuccess()
      return data
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const sendPassportToBlackList = createAsyncThunk<
  any,
  { full_name; passport; type; comment; onSuccess; onError }
>('singleLoan/sendPassportToBlackList', async (props) => {
  const { full_name, passport, type, comment, onSuccess, onError } = props

  const response = await ProjectLoansService.sendPassportToBlackList({
    full_name,
    passport,
    type: Number(type),
    comment,
  })
    .then((data) => {
      onSuccess && onSuccess()
      return data
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const sendSnilsToBlackList = createAsyncThunk<any, { snils; onSuccess; onError }>(
  'singleLoan/sendSnilsToBlackList',
  async (props) => {
    const { snils, onSuccess, onError } = props

    const response = await ProjectLoansService.sendSNilsToBlackList({
      snils,
    })
      .then((data) => {
        onSuccess && onSuccess()
        return data
      })
      .catch((e) => {
        onError && onError()
        throw new Error(e)
      })
    return response
  }
)

export const updateLoanToSettingLimitStatus = createAsyncThunk<
  any,
  { id: string; onSuccess: () => void; onError: () => void }
>('singleLoan/updateLoanToSettingLimitStatus', async (props) => {
  const { id, onSuccess, onError } = props
  const response = await ProjectLoansService.updateStatusToSettingLimit(id)
    .then((data) => {
      onSuccess && onSuccess()
      return data
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const blockUserLk = createAsyncThunk<any, { id; onError?: any; onSuccess?: any }>(
  'singleLoan/blockUserLk',
  async (props) => {
    const { id, onSuccess, onError } = props

    const response = await ProjectLoansService.blockLk(id)
      .then((data) => {
        onSuccess && onSuccess()
        return data
      })
      .catch((e) => {
        if (e.response.status === 403)
          onError && onError('ЛК у данного пользоватея уже заблокирован')
        else onError && onError()
      })
    return response
  }
)

export const unBlockUserLk = createAsyncThunk<any, { id; onError?: any; onSuccess?: any }>(
  'singleLoan/unBlockUserLk',
  async (props) => {
    const { id, onSuccess, onError } = props

    const response = await ProjectLoansService.unBlockLk(id)
      .then((data) => {
        onSuccess && onSuccess()
        return data
      })
      .catch((e) => {
        onError && onError()
      })
    return response
  }
)

export const fetchNBKIHistoryData = createAsyncThunk<any, { id }>(
  'singleLoan/NBKIHistory',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getNBKIData(id).then(({ data }) => data)
    return response
  }
)

export const fetchPtiData = createAsyncThunk<any, { id }>(
  'singleLoan/getPtiData',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPtiData(id).then(({ data }) => data)
    return response
  }
)

export const fetchPtiBRSData = createAsyncThunk<any, { loan_number }>(
  'singleLoan/fetchPtiBRSData',
  async (props) => {
    const { loan_number } = props
    const response = await ProjectLoansService.getPtiBRSData(loan_number).then(({ data }) => data)
    return response
  }
)

export const fetchNBKIScoringData = createAsyncThunk<any, { id }>(
  'singleLoan/NBKIScoring',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getNBKIScoringV2(id).then(({ data }) => data)
    return response
  }
)
export const fetchServicesData = createAsyncThunk<any, { loanNumber }>(
  'singleLoan/ServicesData',
  async (props) => {
    const { loanNumber } = props
    const response = await ProjectLoansService.getServices(loanNumber).then(({ data }) => data)
    return response
  }
)

export const changeLoanStatus = createAsyncThunk<
  any,
  {
    loan_id: string
    timeLimit: number
    sumLimit: number
    comment?: string
    status_name: string
    successAction?: () => void
    errorAction?: () => void
  }
>('singleLoan/changeLoanStatus', async (props) => {
  const {
    loan_id: id,
    comment = '',
    timeLimit,
    sumLimit,
    status_name,
    successAction,
    errorAction,
  } = props

  const response = await ProjectLoansService.changeStatus(
    id,
    timeLimit,
    sumLimit,
    comment,
    status_name
  )
    .then(({ data }) => {
      if (successAction) successAction()
      return { ...data, comment, timeLimit, sumLimit, status_name }
    })
    .catch((e) => {
      if (errorAction) errorAction()
      throw new Error(e)
    })

  return response
})

export const changeLoanStatusRevision = createAsyncThunk<
  any,
  {
    loan_id: string
    timeLimit: number
    sumLimit: number
    code: number
    proposed_other_income?: string
    comment?: string
    successAction?: () => void
    errorAction?: () => void
  }
>('singleLoan/changeStatusRevision', async (props) => {
  const {
    loan_id: id,
    comment = '',
    timeLimit,
    sumLimit,
    code,
    successAction,
    errorAction,
    proposed_other_income,
  } = props

  const response = await ProjectLoansService.changeStatusRevision(
    id,
    timeLimit,
    sumLimit,
    comment,
    code,
    proposed_other_income
  )
    .then(({ data }) => {
      if (successAction) successAction()
      return { ...data, comment, timeLimit, sumLimit, code }
    })
    .catch((e) => {
      if (errorAction) errorAction()
      throw new Error(e)
    })

  return response
})

export const fetchLoanAccept = createAsyncThunk<
  any,
  { loan_id: string; timeLimit: number; sumLimit: number; comment?: string }
>('singleLoan/LoanAccept', async (props) => {
  const { loan_id: id, comment = '', timeLimit, sumLimit } = props

  const response = await ProjectLoansService.loanAccept(id, timeLimit, sumLimit, comment).then(
    ({ data }) => ({ ...data, comment, timeLimit, sumLimit })
  )
  return response
})

export const fetchLoanAcceptWithoutLimit = createAsyncThunk<
  any,
  { loan_id: string; comment?: string }
>('singleLoan/fetchLoanAcceptWithoutLimit', async (props) => {
  const { loan_id: id, comment = '' } = props
  const response = await ProjectLoansService.loanAcceptWithoutLimit(id, comment).then(
    ({ data }) => ({
      ...data,
      comment,
    })
  )
  return response
})

export const fetchLoanRefuse = createAsyncThunk<
  any,
  {
    loan_id: string
    byClient: boolean
    reject_code_id?: number
    ban_user?: boolean
    timeLimit?: number
    sumLimit?: number
    comment?: string
    notice?: boolean
    onSuccess?: () => void
    onError?: () => void
  }
>('singleLoan/LoanRefuse', async (props) => {
  const {
    loan_id: id,
    reject_code_id,
    comment = '',
    timeLimit,
    sumLimit,
    byClient = false,
    notice = true,
    ban_user,
    onSuccess,
    onError,
  } = props

  const response = await ProjectLoansService.loanRefuse(
    id,
    reject_code_id,
    byClient,
    comment,
    ban_user,
    notice
  )
    .then(({ data }) => {
      if (onSuccess) onSuccess()
      return {
        ...data,
        comment,
        timeLimit,
        sumLimit,
      }
    })
    .catch(() => {
      if (onError) onError()
    })
  return response
})

export const fetchUpdatedINN = createAsyncThunk<
  any,
  { id: string; successAction?: () => void; errorAction?: () => void }
>('singleLoan/updatedINN', async (props) => {
  const { id, successAction, errorAction } = props
  const response = await ProjectLoansService.getUpdatedINN(id).then(({ data }) => {
    successAction()
    return { data, errorAction }
  })
  return response
})

export const patchUpdatedINN = createAsyncThunk<any, { id: string }>(
  'singleLoan/patchINN',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getUpdatedINN(id).then(({ data }) => data)
    return response
  }
)

export const getLoanComments = createAsyncThunk<any, { id: string }>(
  'singleLoan/getComments',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getLoanComments(id).then(({ data }) => data)
    return response
  }
)

export const getStatusesHistory = createAsyncThunk<any, { id: string }>(
  'singleLoan/getStatusesHistory',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getStatusesHistory(id).then(({ data }) => data)
    return response
  }
)

export const createLoanComments = createAsyncThunk<
  any,
  {
    userId: string
    loanId: string
    comment: string
    successAction?: () => void
    errorAction?: () => void
  }
>('singleLoan/createComment', async (props) => {
  const { userId, loanId, comment, successAction, errorAction } = props
  const response = await ProjectLoansService.createLoanComments(userId, loanId, comment)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch((e) => errorAction())
  return response
})

export const updateLoanComments = createAsyncThunk<
  any,
  { loanId: string; comment: string; successAction?: () => void; errorAction?: () => void }
>('singleLoan/updateComment', async (props) => {
  const { loanId, comment, successAction, errorAction } = props
  const response = await ProjectLoansService.updateLoanComments(loanId, comment)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch((e) => errorAction())
  return response
})

export const fetchDeleteImageById = createAsyncThunk<
  any,
  { image_id: string; user_id: string; onError?: any }
>('singleLoan/deleteImageById', async (props: any) => {
  const { image_id, user_id, onError } = props
  const response = await ProjectLoansService.deleteImage(image_id, user_id)
    .then(({ data }) => data)
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const fetchAddImage = createAsyncThunk<
  any,
  {
    image: string
    image_type: string
    image_extension: string
    user_id: string
    onError?: any
    onSuccess?: any
  }
>('singleLoan/addImage', async (props: any) => {
  const { image, image_type, image_extension, user_id, onError, onSuccess } = props
  const response = await ProjectLoansService.addImage(image, image_type, image_extension, user_id)
    .then(() => {
      onSuccess && onSuccess()
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const getScoringResult = createAsyncThunk<any, { id: string }>(
  'singleLoan/fetchExternalScoringResult',
  async (props) => {
    const { id } = props
    const response = await fetchExternalScoringResult(id)
      .then(({ data }) => data)
      .catch((error) => error)
    return response
  }
)

export const getPaymentsResult = createAsyncThunk<any, { id: string }>(
  'singleLoan/getPaymentsResult',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPayments(id).then(({ data }) => data)
    return response
  }
)

export const fetchLoanTransactionsByNumber = createAsyncThunk<any, { loan_number: number }>(
  'singleLoan/fetchLoanTransactions',
  async (props) => {
    const { loan_number } = props
    const response = await ProjectLoansService.getLoanTransactions(loan_number).then(
      ({ data }) => ({
        data,
      })
    )
    return response
  }
)

export const fetchLoanDebtByNumber = createAsyncThunk<any, { number: number }>(
  'singleLoan/fetchLoanDebt',
  async (props) => {
    const { number } = props
    const response = await ProjectLoansService.getLoanDebt(number).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchAdditionalContacts = createAsyncThunk<any, { id: string }>(
  'singleLoan/fetchAdditionalContacts',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getAdditionalContacts(id).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const updateAdditionalContacts = createAsyncThunk<
  any,
  {
    id: string
    body: IAdditionalContactsPayload
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/updateAdditionalContacts', async (props) => {
  const { id, body, onError, onSuccess } = props
  const response = await ProjectLoansService.updateAdditionalContacts(id, body)
    .then((data) => {
      onSuccess && onSuccess()
      return data
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const createAdditionalContacts = createAsyncThunk<
  any,
  {
    userId: string
    body: IAdditionalContactsPayload
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/createAdditionalContacts', async (props) => {
  const { userId, body, onError, onSuccess } = props

  const response = await ProjectLoansService.createAdditionalContacts(userId, [body])
    .then((data) => {
      onSuccess && onSuccess()
      return data
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const deleteAdditionalContacts = createAsyncThunk<
  any,
  {
    familiarId: string
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/deleteAdditionalContacts', async (props) => {
  const { familiarId, onError, onSuccess } = props

  const response = await ProjectLoansService.deleteAdditionalContacts(familiarId)
    .then((data) => {
      onSuccess && onSuccess()
      return data
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const fetchBankruptData = createAsyncThunk<any, { userId: string }>(
  'singleLoan/fetchBankruptData',
  async (props) => {
    const { userId } = props
    const response = await ProjectLoansService.fetchBankruptData(userId).then(({ data }) => data)
    return response
  }
)

export const fetchLoanGeneralInfoData = createAsyncThunk<any, { loanNumber: string }>(
  'singleLoan/fetchLoanGeneralInfoData',
  async (props) => {
    const { loanNumber } = props
    const response = await ProjectLoansService.fetchLoanGeneralInfoData(loanNumber).then(
      ({ data }) => data
    )
    return response
  }
)

export const updateBankruptData = createAsyncThunk<
  any,
  {
    userId: string
    body: IBankruptDataItems
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/updateBankruptData', async (props) => {
  const { userId, body, onSuccess, onError } = props
  const response = await ProjectLoansService.updateBankruptData(userId, body)
    .then(() => {
      onSuccess && onSuccess()
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const createBankruptData = createAsyncThunk<
  any,
  {
    userId: string
    body: IBankruptDataItems
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/createBankruptData', async (props) => {
  const { userId, body, onSuccess, onError } = props
  const response = await ProjectLoansService.createBankruptData(userId, body)
    .then(() => {
      onSuccess && onSuccess()
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const deleteBankruptData = createAsyncThunk<
  any,
  {
    userId: string
    onError?: () => void
    onSuccess?: () => void
  }
>('singleLoan/deleteBankruptData', async (props) => {
  const { userId, onSuccess, onError } = props
  const response = await ProjectLoansService.deleteBankruptData(userId)
    .then(() => {
      onSuccess && onSuccess()
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const fetchCheckIsBlacklisted = createAsyncThunk<any, { userId: string }>(
  'singleLoan/fetchCheckIsBlacklisted',
  async (props) => {
    const { userId } = props
    const response = await ProjectLoansService.checkIsBlacklisted(userId).then(({ data }) => data)
    return response
  }
)

export const fetchNBKITransferData = createAsyncThunk<any, { customer_id: string }>(
  'singleLoan/fetchNBKITransferData',
  async (props) => {
    const { customer_id } = props
    const response = await ProjectLoansService.fetchNBKITransferData(customer_id).then(
      ({ data }) => data
    )
    return response
  }
)
