/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Module } from 'components'
import { ImageModal } from 'containers'
import { GlobalState } from 'index'
import { useGetPhotoData } from 'features/loan/loanSelectors'
import { addUndoAction, removeUndoIdAction } from 'features/undoAction/undoActionSlice'
import { fetchDeleteImageById } from 'features/loan/extra'
import { LoanStatus } from 'features/loan/types'
import { StyledImageWrapper } from './Modules.styles'

const moduleId = 'agentPhoto'

export const AgentPhoto: any = ({
  openedModules,
  onToggleModule,
  claimsResolve,
  photoType,
  userId,
}): JSX.Element => {
  const dispatch = useDispatch()
  const {
    loanImageDataFetch: { agentDataPhotoFetching },
    loadData: { loan_status: loanStatus },
  } = useSelector((store: GlobalState) => store.singleLoan)
  const [, , deleteImages] = useSelector((state: GlobalState) => state.undoAction)

  const [showModal, setShowModal] = useState({
    show: false,
    content: null,
  })

  const images = useGetPhotoData()
  const isOpen = openedModules.includes(moduleId)

  const handleExpand = (): void => onToggleModule(moduleId)

  const handleCloseModal = (): void =>
    setShowModal({
      ...showModal,
      show: false,
    })

  const handleShowModal =
    ({ image }) =>
    () =>
      setShowModal({
        ...showModal,
        show: true,
        content: image,
      })

  const removeImage = (e, id) => {
    e.stopPropagation()

    dispatch(
      addUndoAction({
        title: 'Отменить удаление',
        action: () =>
          dispatch(
            fetchDeleteImageById({
              image_id: id,
              user_id: userId,
              onError: () => {
                dispatch(removeUndoIdAction(id))
              },
            })
          ),
        cancelAction: () => dispatch(removeUndoIdAction(id)),
        id,
      })
    )
  }

  return (
    <Module.Wrapper isOpen={isOpen} editable={false} isEdited={false} id={moduleId}>
      <ImageModal.ModalContent
        userId={userId}
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        imageContent={showModal.content}
        onClose={handleCloseModal}
        type='agentPhoto'
        removable
      />
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            {photoType === 'agentPhoto' ? 'Фото Агента' : 'Скан документов'}
            {images?.agentDataPhoto.length === 0 ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}{' '}
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <Module.ImageBlock>
          <StyledImageWrapper>
            {agentDataPhotoFetching && <Module.ImageLoader />}
            {images &&
              images?.agentDataPhoto.length > 0 &&
              images.agentDataPhoto.map((image) => (
                <Module.ImageWrapper
                  onClick={handleShowModal({ image })}
                  key={image.image_id}
                  hide={deleteImages.includes(image.image_id)}
                  createdAt={image?.created_at}
                >
                  <Module.SmallImage url={image.photo_url} />
                  {claimsResolve && (
                    <Module.ImageDelete onClick={(e) => removeImage(e, image.image_id)} />
                  )}
                  {/* <Module.ImageDelete onClick={(e) => removeImage(e, image.image_id)} /> */}
                </Module.ImageWrapper>
              ))}
          </StyledImageWrapper>
        </Module.ImageBlock>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
