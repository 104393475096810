/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { NavMenuType } from 'App'
import { GlobalState } from 'index'
import { NavigationContext } from 'app/contexts'
import { Button } from 'components'

import { fetchAgentUsernames } from 'features/dict/extra'
import { getAgentList } from 'features/settings/extra'
import { StyledPageWrapper, StyledTaskButtonBlock } from './Setting.styles'
import { Table } from './Table'

import { FaPlus } from 'react-icons/fa'

interface IShowModal {
  title: string
  description: string
  show: boolean
  variant?: 'delete' | 'unassign'
}

const initialModalState = { title: '', description: '', action: null, show: false, admin_id: null }

export const Setting = () => {
  const dispatch = useDispatch()

  const { isFetchingAgents, dataAgents } = useSelector((store: GlobalState) => store.settings)
  const { setNavigation, setNavMenuType } = useContext(NavigationContext)

  const [showModal, setShowModal] = useState<IShowModal>(initialModalState)
  const [assignAgent, setAssignAgent] = useState<string>('')

  useEffect(() => {
    setNavMenuType(NavMenuType.settings)
  }, [setNavMenuType])

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `Управление лидами`,
      backTo: null,
    })
    dispatch(getAgentList())
    dispatch(fetchAgentUsernames())
  }, [])

  const handleAddAgentModal = (): void => {
    setShowModal({
      show: true,
      title: 'Добавление агента.',
      description: 'Выберите агента для добавления',
      variant: 'unassign',
    })
  }

  const tableProps = {
    assignAgent,
    setAssignAgent,
    showModal,
    setShowModal,
    dataAgents,
    initialModalState,
    handleAddAgentModal,
  }

  return (
    <>
      <StyledPageWrapper>
        <StyledTaskButtonBlock>
          <Button
            disabled={false}
            type='smallSuccess'
            pending={false}
            onClick={handleAddAgentModal}
          >
            <FaPlus />
            Добавить агента
          </Button>
        </StyledTaskButtonBlock>
        <Table {...tableProps} />
      </StyledPageWrapper>
    </>
  )
}
